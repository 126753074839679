<template>
  <VariableKpiDivisiForm mode="Ubah" module="Variable KPI"> </VariableKpiDivisiForm>
</template>

<script>
import VariableKpiDivisiForm from './form';

const VariableKpiDivisiUpdate = {
  name: 'VariableKpiDivisiUpdate',
  components: { VariableKpiDivisiForm },
};

export default VariableKpiDivisiUpdate;
</script>
